import programsService from "@/services/programsService";
import applicationService from "@/services/applicationService";
import resourcesService from "@/services/resourcesService";
import trainingsService from "@/services/trainingsService";
import matchesService from "@/services/matchesService";
import { setProgramData } from "@/auth/utils";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "@/libs/utils";
import _uniqBy from 'lodash/uniqBy';
import { 
  linkTypes, 
  locales, 
  formStatus, 
  trainingStatus, 
  localesDisplay, 
  matchStatus, 
  logoType, 
  programStatus, 
  userRoles, 
  userRolesDisplay, 
  questionTypes 
} from "@/models";

const initialState = () => ({
  program: null,
  dashboard: null,
  answerData: null,
  applications: [],
  resources: [],
  trainings: [],
  surveys: [],
  surveyAnswerData: null,
  resources: [],
  landingPageLocaleSelected: {
    id: locales.EN
  },
  myMatches: [],
  allAnswerData: {},
  mentorAnswers: {},
  menteeAnswers: {},
  isPendingAnswers: false,
  isSuccessAnswers: false,
  isErrorAnswers: undefined,
});

const mutations = {
  RESET_STATE(state) {
    Object.assign(state, initialState());
  },

  SET_CURRENT_PROGRAM(state, value) {
    state.program = value;
  },

  SET_DASHBOARD(state, value) {
    state.dashboard = value;
  },

  SET_ANSWER_DATA(state, answerData) {
    state.answerData = answerData;
  },

  SET_QUESTION_ANSWER(state, answer) {
    if(Array.isArray(answer) && answer.length > 0) {
      const questionUpdated = state.answerData.questions.map(q => {
        if(q.id === answer[0].question_id) {
          return q.answers = {
            ...q,
            answers: answer
          };          
        }
        return q;
      });
      state.answerData = {
        ...state.answerData,
        questions: questionUpdated
      };
    }
  },
  SET_QUESTION_STATEMENT_ANSWER(state, answer) {
    if(Array.isArray(answer) && answer.length > 0) {
      answer.forEach(a => {
        const scaleQIndex = state.answerData.questions.findIndex(q => q.type_id === questionTypes.SCALE && a.current_question_id === q.id);
        if(scaleQIndex !== -1) {
          state.answerData.questions[scaleQIndex].statements[a.statement_index].answers = [a];
        }
        
      });
    }
    
  },
  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
  },

  SET_SURVEYS(state, surveys) {
    state.surveys = surveys;
  },

  SET_SURVEY_ANSWER_DATA(state, surveyAnswerData) {
    state.surveyAnswerData = surveyAnswerData;
  },

  SET_RESOURCES(state, resources) {
    state.resources = resources;
  },

  SET_TRAININGS(state, trainings) {
    state.trainings = trainings;
  },

  SET_LANDING_PAGE_LOCALE_SELECTED(state, locale) {
    state.landingPageLocaleSelected = locale;
  },

  SET_MY_MATCHES(state, myMatches) {
    state.myMatches = myMatches;
  },

  SET_ANSWER_DATA_MENTEE(state, data) {
    state.menteeAnswers = data;
  },

  SET_ANSWER_DATA_MENTOR(state, data) {
    state.mentorAnswers = data;
  },
  GET_ANSWERS_BEGIN (state) {
    state.isPendingAnswers = true;
  },
  GET_ANSWERS_ERROR (state, { error }) {
    state.isErrorAnswers = error;
  },
  GET_ANSWERS_FINALLY(state) {
    state.isPendingAnswers = false;
  }
};

const actions = {
  async FETCH_CURRENT_PROGRAM({ state, commit, rootGetters }) {
    try {
      const appCurrentProgram = rootGetters['app/currentProgram'];
      if (appCurrentProgram && appCurrentProgram.id) {
        const result = await programsService.getProgramByProgramId(appCurrentProgram.id);
        if (result && result.data) {
          commit('SET_CURRENT_PROGRAM', result.data);
          commit('programs/GET_PROGRAM_BY_ID_SUCCESS', result, { root: true });
          setProgramData(result.data);
        }
      }
    } catch (e) {
      console.log(e);
    }
  },

  async FETCH_OWN_APPLICATION_SETS({ commit, rootGetters }, params) {
    const result = await applicationService.getApplicationSets(params.programId, params.applicationSetId);
    if (result && result.data) {
      const applications = result.data.applications;
      commit('SET_APPLICATIONS', result.data.applications);

      const userRole = params.role;
      return applications.find(application => application.roles.find(r => r.id === userRole));
    }
  },

  async FETCH_OWN_APPLICATION_ANSWERS({ commit }, params) {
    try {
      commit('GET_ANSWERS_BEGIN');
      const result = await applicationService.getOwnApplicationAnswers(params);
      const {data} = result;
      if (data) {
        if(data.roles[0].id === userRoles.MENTEE) {
          commit("SET_ANSWER_DATA", data);
          commit("SET_ANSWER_DATA_MENTEE", data);
        }
        if(data.roles[0].id === userRoles.MENTOR) {
          commit("SET_ANSWER_DATA", data);
          commit("SET_ANSWER_DATA_MENTOR", data);
        }
      }
    } catch(e) {
      commit('GET_ANSWERS_ERROR', { e });
      throw new Error(e);
    } finally {
      commit('GET_ANSWERS_FINALLY');
    }
  },

  async SAVE_OWN_APPLICATION_ANSWERS({ commit }, payload) {
    const result = await applicationService.saveOwnApplicationAnswers(payload.params, payload.data);
    if (result && result.status === 200) {
      return true;
    }
    return false;
  },

  async FETCH_SURVEYS_BY_PROGRAM({ rootGetters, commit }, {programId, userId}) {
    const result = await programsService.getSurveysByProgramIdAndUserId(
      programId,
      userId
    );

    if (result && result.data) {
      commit("SET_SURVEYS", result.data.items);

      const surveys = result.data.items;
      const userRole = rootGetters['profile/profile'].role;
      return surveys.find(survey => survey.roles.find(r => r.id === userRole));
    }
  },

  async FETCH_OWN_SURVEY_ANSWERS({ commit }, params) {
    const result = await programsService.getSurveyAnswersByProgramId(params);
    if (result && result.data) {
      commit("SET_SURVEY_ANSWER_DATA", result.data);
    }
  },
  async FETCH_OWN_SURVEY_ANSWERS_BY_TOKEN({ commit }, params) {
    const result = await programsService.getSurveyAnswersByToken(params);
    if (result && result.data) {
      commit("SET_SURVEY_ANSWER_DATA", result.data);
    }
  },

  async SAVE_OWN_SURVEY_ANSWERS({ commit }, payload) {
    const result = await programsService.saveOwnSurveyAnswers(payload.params, payload.data);
    if (result && result.status === 200) {
      return true;
    }
    return false;
  },
  async SAVE_OWN_SURVEY_ANSWERS_BY_TOKEN({ commit }, payload) {
    const result = await programsService.saveOwnSurveyAnswersByToken(payload.params, payload.data);
    if (result && result.status === 200) {
      return true;
    }
    return false;
  },

  async FETCH_OWN_RESOURCES({ commit }, params) {
    const result = await resourcesService.getProgramResources(params);
    if (result && result.data) {
      commit("SET_RESOURCES", result.data.items);
    }
  },

  async FETCH_OWN_TRAINING({ commit }, params) {
    const result = await trainingsService.getUserTrainings(params.programId, params.userId);
    if (result && result.data) {
      commit("SET_TRAININGS", result.data.items);
    }
  },

  async FETCH_MY_MATCHES({ commit }, params) {
    const result = await matchesService.listUserMatches(params.programId, params.userId);
    if (result && result.data) {
      commit("SET_MY_MATCHES", result.data.items.filter(m => m.status_id === matchStatus.ACTIVE));
    }
  },

  async FETCH_DASHBOARD({ state, commit, rootGetters }) {
    const appCurrentProgram = rootGetters['app/currentProgram'];
    if (appCurrentProgram && appCurrentProgram.id) {
      const result = await programsService.getProgramSummaryForParticipant(appCurrentProgram.id);
      if (result && result.data) {
        commit('SET_DASHBOARD', result.data);
      }
    }
  },
};

const getters = {
  program: (state, getters, rootState, rootGetters) => {
    const appCurrentProgram = rootGetters['app/currentProgram'];
    const programLogos = appCurrentProgram?.program_logos || [];
    const primaryLogo = programLogos.find(logo => logo.type_id === logoType.PRIMARY);
    const sponsorLogos = programLogos.filter(logo => logo.type_id === logoType.GENERAL);
    const landingPage = appCurrentProgram ? appCurrentProgram.landing_pages.find(
      l => l.locale_id === state.landingPageLocaleSelected?.id
    ) : null;
    const landingPageLinks = landingPage ? landingPage.landing_page_links : [];
    const facebookLink = landingPageLinks.find(link => link.type_id === linkTypes.FACEBOOK);
    const twitterLink = landingPageLinks.find(link => link.type_id === linkTypes.TWITTER);
    const linkedinLink = landingPageLinks.find(link => link.type_id === linkTypes.LINKEDIN);
    const youtubeLlink = landingPageLinks.find(link => link.type_id === linkTypes.FACEBOOK);
    const emailLink = landingPageLinks.find(link => link.type_id === linkTypes.EMAIL);
    const websiteLink = landingPageLinks.find(link => link.type_id === linkTypes.WEBSITE);
    const recruitmentVideoLink = landingPageLinks.find(link => link.type_id === linkTypes.RECRUITMENT_VIDEO);

    return {
      ...state.program,
      id: appCurrentProgram ? appCurrentProgram.id : null,
      type_id: appCurrentProgram ? appCurrentProgram.type_id : null,
      program_name: appCurrentProgram ? appCurrentProgram.name : '',
      url: appCurrentProgram ? appCurrentProgram.path : '',
      time_zone: appCurrentProgram ? appCurrentProgram.time_zone : '',
      theme_primary_color: appCurrentProgram ? appCurrentProgram.theme_primary_color : PRIMARY_COLOR,
      theme_secondary_color: appCurrentProgram ? appCurrentProgram.theme_secondary_color : SECONDARY_COLOR,

      participant_visibility: state.program?.participant_visibility,
      sponsor_logos: "",
      header: appCurrentProgram ? appCurrentProgram.name : '',
      tagline: landingPage ? landingPage.tagline : '',
      description: landingPage ? landingPage.description : '',
      landingPageTitle: landingPage ? landingPage.header : '',
      mentee_available: 0,
      mentees_body: landingPage ? landingPage.landing_page_role_descriptions.find(r => r.role_id === userRoles.MENTEE)?.description : '',
      mentor_available: 0,
      mentor_body: landingPage ? landingPage.landing_page_role_descriptions.find(r => r.role_id === userRoles.MENTOR)?.description : '',

      landing_page_links: landingPageLinks,
      facebook_url: facebookLink ? facebookLink.url : '',
      twitter_url: twitterLink ? twitterLink.url : '',
      youtube_url: youtubeLlink ? youtubeLlink.url : '',
      linkedin_url: linkedinLink ? linkedinLink.url : '',
      email: emailLink ? emailLink.url : '',
      website_url: websiteLink ? websiteLink.url : '',
      recruitment_video_url: recruitmentVideoLink ? recruitmentVideoLink.url : '',

      status: 1,
      status_id: appCurrentProgram ? appCurrentProgram.status_id : programStatus.INACTIVE,
      registration_status: appCurrentProgram ? appCurrentProgram.registration_status: {is_active_participant_limit_reached: false, applications: []},
      program_logos: programLogos,
      logo: primaryLogo ? primaryLogo.url : '',
      banner_image: landingPage && landingPage.image ? landingPage.image.url : null,
      program_sponsor_logos: sponsorLogos,
      key_dates: appCurrentProgram && appCurrentProgram.key_dates ? appCurrentProgram.key_dates.map(k =>
        ({
          has_detail_page: k.has_detail_page,
          is_public: k.is_public || k.id === undefined,
          date: k.date,
          title: k.title,
          description: k.description
        })
      ) : [],
      status_code: 1,
      wcag_compliance_id: 3,
      saml_sso_enabled: 0,
      program_roles: appCurrentProgram && appCurrentProgram.program_roles ? appCurrentProgram.program_roles : [],
    };
  },

  programs: () => {
    return {
      total: 1,
      content: "You are a participant in 1 programs",
      events_comming_up: [],
      events_comming_up_quicklink: null,
    };
  },
  completePercentageApplication: state => {
    console.log('state.answerData', state.answerData)
    const totalQ = state.answerData?.questions?.length;
    const nonRatingQ = state.answerData?.questions?.filter(q => q.type_id !== questionTypes.SCALE && ((q.answers && q.answers.length))).length;
    const ratingQ = state.answerData?.questions?.filter(q => q.type_id === questionTypes.SCALE);
    const answeredRatingQ = ratingQ?.filter(q => {
      return q?.statements.find(s => s?.answers && s?.answers.length);
    }).length;
    return Math.round(((nonRatingQ + answeredRatingQ) / totalQ) * 100) || 0;
  },
  completedPercentMentor: state => {
    const totalQ = state.mentorAnswers?.questions?.length;
    const nonRatingQ = state.mentorAnswers?.questions?.filter(q => q.answers && q.answers.length && q.type_id !== questionTypes.SCALE).length;
    const ratingQ = state.mentorAnswers?.questions?.filter(q => q.type_id === questionTypes.SCALE);
    const answeredRatingQ = ratingQ?.filter(q => q?.statements.find(s => s?.answers && s?.answers.length)).length;
    return Math.round(((nonRatingQ + answeredRatingQ) / totalQ) * 100) || 0;
  },
  completedPercentMentee: state => {
    const totalQ = state.menteeAnswers?.questions?.length;
    const nonRatingQ = state.menteeAnswers?.questions?.filter(q => q.answers && q.answers.length && q.type_id !== questionTypes.SCALE).length;
    const ratingQ = state.menteeAnswers?.questions?.filter(q => q.type_id === questionTypes.SCALE);
    const answeredRatingQ = ratingQ?.filter(q => q?.statements.find(s => s?.answers && s?.answers.length)).length; 
    return Math.round(((nonRatingQ + answeredRatingQ) / totalQ) * 100) || 0;
  },
  applicationMentee: (state, _getters, _rootState, rootGetters) => {
    const roles = rootGetters['profile/profile'].roles;
    return state.applications.find(application => application.roles.find(r => r.id === roles.find(r => r === userRoles.MENTEE)));
  },
  applicationMentor: (state, _getters, _rootState, rootGetters) => {
    const roles = rootGetters['profile/profile'].roles;
    return state.applications.find(application => application.roles.find(r => r.id === roles.find(r => r === userRoles.MENTOR)));
  },
  applications: (state, _getters, _rootState, rootGetters) => {
    const dashboard = state.dashboard;
    const applications = dashboard ? dashboard.applications : [];
    const applicationMentor = applications.find(application => application.role_id === userRoles.MENTOR);
    const applicationMentee = applications.find(application => application.role_id === userRoles.MENTEE);
    return {
      mentor: applicationMentor ? {
        completed_percent: applicationMentor.percent_complete,
        uncomplete_percent: Number(100 - applicationMentor.percent_complete),
        content: applicationMentor.percent_complete < 100 ? `${applicationMentor.percent_complete}% completed` : '100% completed and submitted',
      } : null,
      mentee: applicationMentee ? {
        completed_percent: applicationMentee.percent_complete,
        uncomplete_percent: Number(100 - applicationMentee.percent_complete),
        content: applicationMentee.percent_complete < 100 ? `${applicationMentee.percent_complete}% completed` : '100% completed and submitted',
      } : null,
    };
  },
  matches: (state, getters, rootState, rootGetters) => {
    const dashboard = state.dashboard;
    const total = dashboard ? Number(dashboard.matches.total) : 0;
    if(dashboard && dashboard.matches) {
      const matches = dashboard.matches.match_users;
      const getYourMatches = roleId => {
        const profile = rootGetters['profile/profile'];
        if(matches.length > 0) {
          return matches.filter(mu => Number(mu.role_id) === Number(roleId) && profile.email !== mu.user.email );
        }
        return [];
      };
      return {
        total,
        your_mentor: getYourMatches(userRoles.MENTOR),
        your_mentee: getYourMatches(userRoles.MENTEE)
      };
    }
  },
  
  matches_request_count: (state, getters, rootState, rootGetters) => {
    const dashboard = state.dashboard;
    return dashboard ? Number(dashboard.matches_request_count) : 0;
  },

  surveys: state => {
    const dashboard = state.dashboard;
    const total = dashboard ? Number(dashboard.surveys.total) : 0;
    const completed = dashboard ? Number(dashboard.surveys.completed) : 0;
    const uncomplete = total - completed;
    return {
      total,
      uncomplete,
      completed,
      content: `${uncomplete} surveys available`,
      value: `${completed}/${total}`,
    };
  },

  trainings: state => {
    const dashboard = state.dashboard;
    const total = dashboard ? Number(dashboard.trainings.total) : 0;
    const completed = dashboard ? Number(dashboard.trainings.completed) : 0;
    const uncomplete = total - completed;

    return {
      total,
      uncomplete,
      completed,
      content: `${completed} of ${total} training module completed`,
      value: `${completed}/${total}`
    };
  },

  resources: state => {
    const dashboard = state.dashboard;
    const total = dashboard ? Number(dashboard.resources.total) : 0;

    return {
      total,
      content: `${total} resource${total > 1 ? 's' : ''} available in this program`,
    };
  },

  participants: state => {
    return {
      total: state.program.participants_count,
    };
  },

  messages: (state, getters, rootState, rootGetters) => {
    return { unread: rootGetters['programs/messagesCountUnread'] ?? 0 };
  },

  getApplicationByUserRole: state => {
    return role => {
      return state.applications.find(application => application.roles.find(r => r.id === role));
    };
  },

  getTranslationTextByUserLocale: (state, getters, rootState, rootGetters) => {
    return (translations, propertyName, preferredLanguageId) => {
      const userLocaleId =  rootGetters['profile/profile'].preferred_language_id || preferredLanguageId;
     
      const selectedTranslation = translations.find(translation => translation.locale_id === userLocaleId);
      const defaultEnglishTranslation = translations.find(translation => translation.locale_id === locales.EN);
      if (selectedTranslation) {
        return selectedTranslation[propertyName];
      } else if (defaultEnglishTranslation) {
        return defaultEnglishTranslation[propertyName];
      }
      return `${localesDisplay[userLocaleId]} translation not found!`;
    };
  },

  ownApplication: (state, _getters, _rootState, rootGetters) => {
    const userRole = rootGetters['profile/profile'].role;
    return state.applications.find(application => application.roles.find(r => r.id === userRole));
  },

  answerData: state => {
    return state.answerData;
  },

  mentorSynonym: (_state, getters) => {
    if (getters.program && getters.program.program_roles.length) {
      const programRole = getters.program.program_roles.find(programRole => {
        if (programRole.role_id === userRoles.MENTOR) {
          return programRole;
        }
      });
      return programRole ? programRole.alternative_role_name : null;
    }
    return '';
  },

  mentorDisplay: (state, getters) => {
    if (getters.mentorSynonym) { return getters.mentorSynonym; }

    return userRolesDisplay[userRoles.MENTOR];
  },

  menteeSynonym: (_state, getters) => {
    if (getters.program && getters.program.program_roles.length) {
      const programRole = getters.program.program_roles.find(programRole => {
        if (programRole.role_id === userRoles.MENTEE) {
          return programRole;
        }
      });
      return programRole ? programRole.alternative_role_name : null;
    }
    return '';
  },

  menteeDisplay: (state, getters) => {
    if (getters.menteeSynonym) { return getters.menteeSynonym; }

    return userRolesDisplay[userRoles.MENTEE];
  },

  ownSurvey: (state, _getters, _rootState, rootGetters) => {
    const userRole = rootGetters['profile/profile'].role;
    return state.surveys.find(survey => survey.roles.find(r => r.id === userRole));
  },

  surveyAnswerData: state => {
    return state.surveyAnswerData;
  },
  isApplicationAnswersLoading:  state => state.isPendingAnswers
};

export default {
  namespaced: true,
  mutations,
  actions,
  state: initialState(),
  getters,
};
