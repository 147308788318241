import Vue from 'vue';
import VueRouter from 'vue-router';
import { userRoles } from '@/models/userRoles';
import { AOM_MAIN_DOMAIN, AOM_PORTAL_SUB_DOMAIN, AOM_ADMIN_SUB_DOMAIN } from '@/constants/app';
import { makeErrorToast } from "@/libs/utils";
// Routes
import { canNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, clearLocalStorage, getHomeRouteForLoggedInUser, getProgramList } from '@/auth/utils';
import apps from './routes/apps';
import pages from './routes/pages';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'auth-login' } },
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});
router.beforeResolve((to, from, next) => {
  const isLoggedIn = isUserLoggedIn();
  const user = store.getters['profile/profile'];

  if (isLoggedIn?.roles && to.name?.indexOf('admin') === 0) {
    const hasAdminRole = isLoggedIn.roles?.includes(userRoles.ADMIN);
    if (!hasAdminRole) {
      return next({ name: 'error-404' });    
    }
  }

  if (isLoggedIn?.roles && to.name?.indexOf('champion') === 0) {
    const hasAdminOrChampRole = isLoggedIn.roles?.includes(userRoles.ADMIN) || 
        isLoggedIn.roles?.includes(userRoles.CHAMPION) || 
        isLoggedIn.roles?.includes(userRoles.PROGRAM_ADMIN);
    if (!hasAdminOrChampRole) {
      return next({ name: 'error-404' });    
    }
  }

  if(to.meta.resource === 'ParticipantNavs' && isLoggedIn && user) {
    const hasParticipantRole = user.roles?.includes(userRoles.MENTEE) || user.roles?.includes(userRoles.MENTOR);
    if(!hasParticipantRole) {
      next({name: 'participant-select-role', params: {clientSlug: to.params.clientSlug, programPath: to.params.programPath}});
    }
    next();
  }
  next();
});
router.beforeEach(async (to, from, next) => {
  if(to.name === 'participant-login' && to.query.token) {
    clearLocalStorage();
  }
  const isLoggedIn = isUserLoggedIn();
  // check if PP Portal
  await checkParticipantPortal(to,next,isLoggedIn);
  //Handle subdomain push
  if(to.name.indexOf('participant-') === 0 && to.name === 'participant-login') {
    const protocol = window.location.protocol;
    const fullPath = to.fullPath;
    const currentPath = `${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}`;
    const queryString = new URLSearchParams(window.location.search).toString();

    if(window.location.host !== currentPath) {
      // only redirect if coming off Landing Page
      window.location = `${protocol}//${AOM_PORTAL_SUB_DOMAIN}.${AOM_MAIN_DOMAIN}${fullPath}${queryString}`;
    }
  }
  // Public Pages
  if (!isLoggedIn && to.meta.publicRoute ) {
    return next();
  } 
  // Not logged
  if ( !isLoggedIn && to.name !== 'auth-login' && to.meta.resource !== 'ParticipantNavs') {
    return next({ name: 'auth-login' });
  }
  if ( !isLoggedIn && to.name !== 'participant-login' && to.meta.resource === 'ParticipantNavs') {
    return next({ 
      name: 'participant-login', 
      params: { clientSlug: to.params.clientSlug, programPath: to.params.programPath }, 
      query: { next: to.path } 
    });
  }
  // Logged in and page meta has redirectIfLoggedIn
  if (to.meta.redirectIfLoggedIn && isLoggedIn && to.name !== 'participant-login') {
    const route = await redirectToHome();
    next(route);
  }
  // Logged in and access Login
  if (isLoggedIn && to.name === 'auth-login') {
    const route = await redirectToHome();
    next(route);
  }

  if (isLoggedIn && to.name === 'participant-login') {
    const route = await redirectToHome();
    next(route);
  }
  // If Champ portal fetch program by id in url
  if (isLoggedIn && to.params.id && to.name.indexOf('champion-') === 0) {
    fetchProgramById(to);
  }

  // Get actionable events
  if (to.meta.fetchActionableEvent) {
    getActionableEvents(to);
  }

  if (isLoggedIn && store.getters['app/currentProgram'] && to.name.indexOf('participant-') === 0) {
    store.commit('programs/SET_CURRENT_PROGRAM_ID', store.getters['app/currentProgram'].id);
  }

  return next();
});
async function checkParticipantPortal(to, next,isLoggedIn) {
  if (to.name.indexOf('participant-') === 0) {
    const storeCurrentProgram = store.state.app.currentProgram;
    if (
      storeCurrentProgram && 
      storeCurrentProgram?.client?.path !== to.params.clientSlug && 
      storeCurrentProgram?.path !== to.params.programPath
    ) {
      store.commit('app/SET_CURRENT_PROGRAM', null);
    }
    return fetchCurrentPortalProgram(to).then(() => {
      if (isLoggedIn && to.name != "participant-verify") {
        return fetchPortalProgramById();
      }
    });
  }
}
async function redirectToHome() {
  await store.dispatch('profile/FETCH_AUTHED_USER');
  const user = store.getters['profile/profile'];
  const home = getHomeRouteForLoggedInUser(user ? user.role : null);
  const programList = getProgramList();
  if (user.role === userRoles.CHAMPION || user.role === userRoles.PROGRAM_ADMIN) {
    return { name: home.name, params: { id: user.programs[0] }, replace: true };
  }
  if (user.role === userRoles.ADMIN) {
    return { name: home.name, replace: true };
  }
  if (user.role === userRoles.MENTOR || user.role === userRoles.MENTEE || user.role === userRoles.PARTICIPANT_CANDIDATE) {
    const usersProgram =  store.getters['app/currentProgram'];

    return { name: home.name, params: {clientSlug: usersProgram.client.path, programPath : programList[0].path }, replace: true };
  }
}

async function fetchProgramById (route) {
  if(route?.params?.id) {
    const programId = route.params.id;
    store.commit('programs/SET_CURRENT_PROGRAM_ID', programId);
    await store.dispatch('programs/FETCH_PROGRAM_BY_ID', programId);

  }
}

async function getActionableEvents(route) {
  if(route?.params?.id) {
    const programId = route.params.id;
    await store.dispatch('app/FETCH_PROGRAM_ACTIONABLE_EVENTS', programId);
  } else {
    await store.dispatch('app/FETCH_ACTIONABLE_EVENTS');
  }
}
async function fetchCurrentPortalProgram(to) {
  try {
    const programPath = to.params.programPath;
    const subdomain = to.params.clientSlug;
    if (subdomain && programPath) {
      await store.dispatch('app/FETCH_CURRENT_PROGRAM', { subdomain, programPath});
    }
  } catch(e) {
    console.log(e);
  }
}
async function fetchPortalProgramById() {
  try {
    await Promise.all([
      store.dispatch('participants/FETCH_CURRENT_PROGRAM'),
      // @TODO does it need to run on every route change for PP
      store.dispatch('participants/FETCH_DASHBOARD')
    ]);
  } catch(e) {
    console.log(e);
  }
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach((to, _from) => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
    appLoading.style.display = 'none';
  }
  // Display page title
  let pageTitle = "Art of Mentoring";
  if (to.meta.resource === 'AdminRoutes') {
    pageTitle = "Art of Mentoring | Admin";
  } else if (to.meta.resource === 'ChampionRoutes') {
    pageTitle = "Art of Mentoring | Champion";
  } else if (to.meta.resource === 'ParticipantNavs') {
    pageTitle = "Art of Mentoring | Participant";
  }
  Vue.nextTick(() => {
    document.title = pageTitle;
  });
});

export default router;
