export const programTypes = Object.freeze({
  STANDARD: 1,
  PARTNER_PREFERENCING: 2,
  MENTORING_ON_DEMAND: 3,
  TRAINING: 4
});

export const programTypeDisplay = [
  { id: programTypes.STANDARD, text: 'Standard'},
  { id: programTypes.PARTNER_PREFERENCING, text: 'Partner Preferencing'},
  { id: programTypes.MENTORING_ON_DEMAND, text:  'Mentoring on Demand'},
  { id: programTypes.TRAINING, text:  'Training'},
];

export const programPlans = Object.freeze({
  PROFESSIONAL: 1,
  PLATINUM: 2,
  PLATINUM_FLEXI: 3
});
  
export const programPlansDisplay = [
  { id: programPlans.PROFESSIONAL, text: 'Professional'},
  { id: programPlans.PLATINUM, text: 'Platinum'},
  { id: programPlans.PLATINUM_FLEXI, text:  'Platinum Flexi'}
];

export const programStatus = Object.freeze({
  INCOMPLETE: 0,
  INACTIVE: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
  EXPIRED: 4,
  SUSPENDED: 5
});
 

export const programStatusDisplay = {
  [programStatus.INCOMPLETE]: 'Incomplete',
  [programStatus.INACTIVE]: 'Inactive',
  [programStatus.ACTIVE]: 'Active',
  [programStatus.ARCHIVED]: 'Archived',
  [programStatus.EXPIRED]: 'Expired',
  [programStatus.SUSPENDED]: 'Suspended'
};

export const programStatusSelect = Object.keys(programStatusDisplay).map(p => ({value: Number(p), text: programStatusDisplay[p]}));

export const matchOptions = Object.freeze({
  MATCH_DATE: 1,
  DATE: 2,
  REVIEW: 3
});
export const matchOptionsDisplay = [
  { value: [matchOptions.MATCH_DATE], text: 'Match Date'},
  { value: [matchOptions.DATE], text: 'Date'}
];

export const momentTypes = Object.freeze({
  BEFORE: 1,
  AFTER: 2,
  AT_MOMENT: 3
});
export const momentTypesDisplay = [
  { value: [momentTypes.BEFORE], text: 'Before'},
  { value: [momentTypes.AFTER], text: 'After'},
  { value: [momentTypes.AT_MOMENT], text:  'At moment'}
];

 
export const matchDurationValuesDisplay = Array.from({length: 24}, (_, i) => ({value:i + 1, text: i + 1}) );

export const links = Object.freeze({
  FACEBOOK: 1,
  TWITTER: 2,
  YOUTUBE: 3,
  LINKEDIN: 4,
  EMAIL: 5,
  WEBSITE: 6,
  RECRUITMENT_VIDEO: 7
});

export const linkIconsDisplay = {
  [links.FACEBOOK]: 'FacebookIcon',
  [links.TWITTER]: 'TwitterIcon',
  [links.YOUTUBE]: "YoutubeIcon",
  [links.LINKEDIN]: "LinkedinIcon",
  [links.EMAIL]: "MailIcon",
  [links.WEBSITE]: "HomeIcon",
  [links.RECRUITMENT_VIDEO]: "YoutubeIcon"
};
