export const actionableEvent = Object.freeze({
  CUSTOM_DATE: 1000000,
});

export const actionableEventDisplay = [
  { id: actionableEvent.CUSTOM_DATE, name: 'Custom Date' },
];

export const actionableEventsDisplay = {
  [actionableEvent.CUSTOM_DATE]: 'Custom Date',
};

export const actionableEventsSelectFull = [
  { value: [actionableEvent.CUSTOM_DATE], text: 'Custom Date'},
  { value: [actionableEvent.MATCH_START], text: 'Day Sent After Match'},
  { value: [actionableEvent.ON_REGISTRATION], text: 'Participant Registration'},
];

export const actionableEventsSelectForResource = [
  { value: [actionableEvent.MATCH_START], text: 'Day Sent After Match'},
  { value: [actionableEvent.ON_REGISTRATION], text: 'Participant Registration'},
];

export const actionableEventsSelectForTraining = [
  { value: [actionableEvent.ON_REGISTRATION], text: 'Participant Registration'},
  { value: [actionableEvent.MATCH_START], text: 'Day Sent After Match'},
];
