export const matchStatus = Object.freeze({
  NONE:  0,
  LOCKED: 1,
  ACTIVE: 2,
  CANCELLED: 3,
  COMPLETED: 4,
  SCHEDULED: 5,
});
  
export const matchStatusDisplay = {
  [matchStatus.NONE]: 'None',
  [matchStatus.LOCKED]: 'Locked',
  [matchStatus.ACTIVE]: 'Active',
  [matchStatus.CANCELLED]: 'Cancelled',
  [matchStatus.COMPLETED]: 'Completed',
  [matchStatus.SCHEDULED]: 'Scheduled'
};

export const matchStatusDisplaySelect = [
  {value: matchStatus.NONE, text: 'None'},
  {value: matchStatus.LOCKED, text: 'Locked'},
  {value: matchStatus.ACTIVE, text: 'Active'},
  {value: matchStatus.CANCELLED, text: 'Cancelled'},
  {value: matchStatus.COMPLETED, text: 'Completed'}
];
