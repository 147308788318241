import { User, locales, userStatus, userRoles as modelUserRoles } from "@models";
import { authService } from "@services";
import {
  getHighestUserRole,
  getUserRoles,
  getProgramsFromUserRoles,
  parseAbilitiesFromPermissions
} from "@/libs/utils";
import ability from '@/libs/acl/ability';

const userClass = new User();

const initialState = () => ({
  ...userClass,
  id: null,
  role: null,//Guest
  roles: [],
  email: null,
  phone: null,
  username: null,
  first_name: null,
  last_name: null,
  full_name: null,
  avatar: null,
  preferred_language_id: locales.EN,
  programs: [],
  preferred_language: [],
  user_roles: [],
  integrations: [],
  login: null,
  isInUnavailabilityPeriod: false,
  logged_in_by: null,
  user_roles: [],
  created_at: null
});
const mutations = {
  RESET_STATE (state) {
    Object.assign(state, initialState());
  },

  SET_USER_PROFILE (state, userData) {
    Object.keys(userData).forEach(field => {
      if (state.hasOwnProperty(field)) {
        state[field] = userData[field];
      }
    });
  },
  SET_USER_ROLE (state, role) {
    state.role = role;
  },
  SET_IS_USER_IN_AVAILBILITY (state, isInUnavailabilityPeriod) {
    state.isInUnavailabilityPeriod = isInUnavailabilityPeriod;
  }
};

const getters = {
  profile: state => state,

  isLoggedIn: state => {
    return !!state.id;
  },
  programIds: state => {
    return state.programs;
  },
  loggedUserId: state => state.id,

  loggedUser: state => state,

  userProgramSettings: state => state.user_program_settings,
  
  preferredLanguageId: state => state.preferred_language?.id || locales.EN,

  isProgramAdmin: state => programId => state?.user_roles.find(
    r => r.role_id === modelUserRoles.PROGRAM_ADMIN && r.status_id === userStatus.ACTIVE && r.program_id === programId
  ) !== undefined,
  
  isChampion: state => programId => state?.user_roles.find(
    r => r.role_id === modelUserRoles.CHAMPION && r.status_id === userStatus.ACTIVE && r.program_id === programId
  ) !== undefined,

  isAdmin: state => state?.user_roles.find(r => r.role_id === modelUserRoles.ADMIN && r.status_id === userStatus.ACTIVE) !== undefined,

  isUserMentor: state => state.roles.includes(r => r === modelUserRoles.MENTOR),

  isUserMentee: state => state.roles.includes(r => r === modelUserRoles.MENTEE),
};
const actions = {
  async FETCH_AUTHED_USER({ rootGetters, commit, dispatch }) {
    try {
      const response = await authService.getUserProfile();
      const { data } = response;
      const appCurrentProgram = rootGetters['app/currentProgram'];
      const isParticipantPortal = rootGetters['app/isParticipantPortal'];
      const userRoles = appCurrentProgram && appCurrentProgram.id && isParticipantPortal
        ? data.user_roles.filter(r => r.program_id === appCurrentProgram.id)
        : data.user_roles;
      data.role = updateUsersRole(rootGetters, data, userRoles);
      data.roles = getUserRoles(userRoles);
      data.programs = getProgramsFromUserRoles(userRoles);
      data.ability = parseAbilitiesFromPermissions({
        id: data.id,
        roles: data.roles,
        permissions: []
      });    
      ability.update(data.ability);
      commit('SET_USER_PROFILE', data);
    } catch (error) {
      throw error;
    }
  },
};
function updateUsersRole (rootGetters, user, userRoles) {
  const isChampionPortal = rootGetters['app/isChampionPortal'];
  const isAdminPortal = rootGetters['app/isAdminPortal'];
  const active = activeRoles(userRoles);
  if (isChampionPortal && activeRolesAsProgramAdmin(active)) {
    user.role = modelUserRoles.PROGRAM_ADMIN;
  } else if(isChampionPortal && activeRoleAsChampion(active)) {
    user.role = modelUserRoles.CHAMPION;
  } else if(isAdminPortal && activeRolesAsAdmin(active) ) {
    user.role = modelUserRoles.ADMIN;
  } else {
    user.role = getHighestUserRole(active);
  }
  return user.role;
}
function activeRoles(roles) {
  return roles.filter(r => r.status_id === userStatus.ACTIVE);
}
function activeRolesAsAdmin(activeRoles) {
  return activeRoles.filter(r => r.role_id === modelUserRoles.ADMIN).length > 0;
}
function activeRolesAsProgramAdmin(activeRoles) {
  return activeRoles.filter(r => r.role_id === modelUserRoles.PROGRAM_ADMIN).length > 0;
}
function activeRoleAsChampion(activeRoles) {
  return activeRoles.filter(r => r.role_id === modelUserRoles.CHAMPION).length > 0;
}
export default {
  namespaced: true,
  mutations,
  state: initialState(),
  getters, 
  actions
};
